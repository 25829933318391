import { AuthContext } from 'context/AuthContext'
import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Lottie from 'react-lottie'
import * as animationData from 'assets/lottie/animation_onboarding_benefits.json'
import BlockButton from 'components/buttons/BlockButton'

const LoginStep = ({
  currentStep,
  setCurrentStep,
  scrollToWidget,
  email: propEmail,
  setEmail: setPropEmail,
  loginState,
}) => {
  const { t } = useTranslation('common')

  const [email, setEmail] = useState('Marcus')
  const [emailError, setEmailError] = useState(false)
  const [password, setPassword] = useState('J%Pd$&xa*&B%%2Ux&Z')
  const { loginUser } = useContext(AuthContext) // Get the loginUser function from AuthContext

  const [defaultOptions] = useState({
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  })

  const handleRegister = async (e) => {
    e.preventDefault()
    try {
      const response = await fetch('https://tinyaffirmation.com/wp-json/custom/v1/register/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
      })
      const data = await response.json()
      if (response.ok) {
        // Automatically log the user in after registration
        handleLogin()
      } else {
        alert('Error during registration: ' + data.message)
      }
    } catch (error) {
      console.error('Error registering:', error)
    }
  }

  const handleLogin = async (e) => {
    e.preventDefault()

    const response = await fetch('https://tinyaffirmation.com/wp-json/jwt-auth/v1/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Include cookies for CORS
      body: JSON.stringify({ username: email, password }),
    })

    const data = await response.json()

    if (response.ok && data.token) {
      // Store the token in localStorage or cookies
      localStorage.setItem('authToken', data.token)

      console.log('User logged in:', data.token)
      // Now send the token to the backend to set WordPress cookies
      const setCookieResponse = await fetch(
        'https://tinyaffirmation.com/wp-json/custom/v1/set-cookie',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token: data.token }),
          credentials: 'include',
        },
      )

      if (setCookieResponse.ok) {
        console.log('User logged into WordPress backend.')
      } else {
        console.error('Failed to log in to WordPress backend.')
      }
    } else {
      // Handle login failure
      console.error('Login failed', data)
    }
  }

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  const sendEmailToAPI = async () => {
    const payload = {
      email: email,
      listIds: [9],
      attributes: {
        // Include all required attributes here
      },
    }
    const headers = {
      'Content-Type': 'application/json',
      'api-key': 'your-api-key-here',
    }
    try {
      const response = await fetch('https://api.sendinblue.com/v3/contacts', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(payload),
      })
      const data = await response.json()
      console.log(data)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div>
      <div className="animated fadeIn">
        <Lottie
          options={defaultOptions}
          height={200}
          width={200}
          isStopped={defaultOptions.isStopped}
          isPaused={defaultOptions.isPaused}
        />
      </div>
      <span className="onboarding-headline animated fadeIn">{t('welcome-page.login-step-h1')}</span>
      <span className="onboarding-text animated fadeIn">
        {t('welcome-page.login-step-input-text')}
      </span>

      <div className="input-box animated fadeIn" style={{ zIndex: 9999 }}>
        <div className="input-box-headline">{t('welcome-page.email-login-info')}</div>
        <div className="input-box-input-wrapper">
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onClick={() => setEmail('')}
            className={`input-box-input ${emailError ? 'is-error' : ''}`}
            placeholder={t('welcome-page.login-step-email-input-label')}
          />
        </div>
        <div className="input-box-headline">{t('email')}</div>
        <div className="input-box-input-wrapper">
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setPassword(e.target.value)}
            onClick={() => setPassword('')}
            className={`input-box-input`}
            placeholder={t('password')}
          />
        </div>
      </div>

      <BlockButton
        isBottom
        disabled={!email || !validateEmail(email)}
        text={t('continue')}
        onClick={() => {
          if (email && validateEmail(email)) {
            handleRegister()
          } else {
            setEmailError(true)
          }
        }}
      ></BlockButton>
    </div>
  )
}

export default LoginStep
