import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PageTitle from 'components/PageTitle';
import SectionHeader from 'components/SectionHeader';
import styled from 'styled-components';
import CheckmarkCircleIcon from 'components/SVG/CheckmarkCircleIcon';
import { API_URL, BASE_URL } from 'constants/URLS';
import StopIcon from 'components/SVG/StopIcon';
import PlayIcon from 'components/SVG/PlayIcon';
import { getLanguage } from 'helper/getLanguage';
import BlockButton from 'components/buttons/BlockButton';
import ContentCard from 'components/ContentCard';
import { useAPI } from 'context/APIcontext';
import CoachingSessions from './CoachingSessions';
import Modal from 'components/Modal';
import CoachingDetail from './CoachingDetail';
import OnboardingPage from 'pages/OnboardingPage/OnboardingPage';

const StyledTrainingPlanDetail = styled.div`
  font-size: 16px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.4;
  color: #111;
  min-height: 23px;
  text-align: center;
  padding-bottom: 160px;

  .image-container {
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
    border-radius: 10px;
    margin: 0;
    position: relative;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    min-height: 430px;
    top: -72px;

    @media (min-width: 720px) {
      top: -50px;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
    }
  }

  .info-container {
    margin: 0 3%;
    max-width: 720px;
    margin-top: -180px;

    @media (min-width: 720px) {
      margin: 0 auto;
    }

    ul {
      margin: 0;

      li {
        list-style: none;
        text-align: center;

        svg {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }
  }
`;

export default function TrainingPlanDetail({ selectedTrainingPlan, handleClose, onPlanStarted }) {
  const { t } = useTranslation('common');

  const { isPremium, coachingSessions, setCurrentTrainingPlan } = useAPI();

  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState('00:00');
  const [inspiringQuote, setInspiringQuote] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const [trainingModules, setTrainingModules] = useState([]);
  const [showLandingPage, setShowLandingPage] = useState(false);

  const audioPlayerRef = useRef(null);
  const intervalIdRef = useRef(null);

  useEffect(() => {
    let quote = selectedTrainingPlan.training_modules[0].training_items.find(
      (item) => item.type === 'InspiringQuote',
    );
    setInspiringQuote(quote);

    let sessions = selectedTrainingPlan.training_modules[0].training_items.filter(
      (item) => item.type === 'Session',
    );

    sessions.map((session) => {
      const sessionDetails = getSessionDetailsById(session.reference_id);
      session.session_details = sessionDetails;

      sessionDetails.fullTags = [];
      sessionDetails.tags.map((tag) => {
        const tagDetail = coachingSessions.tags.find((t) => t.id === tag);
        if (tagDetail && tagDetail.code === '') {
          sessionDetails.fullTags.push(tagDetail.label);
        } else {
        }
      });
    });

    setTrainingModules(sessions);

    const mediaURL = selectedTrainingPlan.training_modules[0].training_items[1].data.media_url;
    if (mediaURL) {
      stopPlayFile();
      const audioPath = `${BASE_URL}/api/coaching/training_plans_${getLanguage()}/${mediaURL}`;
      setCurrentTime('00:00');

      const audio = new Audio(audioPath);
      audio.addEventListener('timeupdate', updateTime);
      audio.addEventListener('ended', handleAudioEnded);
      audioPlayerRef.current = audio;
    }

    const beforeUnloadHandler = () => {
      unloadPage();
    };

    window.addEventListener('beforeunload', beforeUnloadHandler);

    return () => {
      stopPlayFile();
      window.removeEventListener('beforeunload', beforeUnloadHandler);
      unloadPage();
    };
  }, [selectedTrainingPlan]);

  function getSessionDetailsById(reference_id) {
    return coachingSessions.sessions_v3.find((session) => session.id === reference_id);
  }

  const unloadPage = () => {
    stopPlayFile();
    clearInterval(intervalIdRef.current);
    intervalIdRef.current = null;
  };

  const playFile = () => {
    if (audioPlayerRef.current) {
      setIsPlaying(true);
      audioPlayerRef.current.play();
      audioPlayerRef.current.loop = false;
      intervalIdRef.current = setInterval(updateTime, 1000);
    }
  };

  const pausePlayFile = () => {
    if (audioPlayerRef.current) {
      setIsPlaying(false);
      audioPlayerRef.current.pause();
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };

  const stopPlayFile = () => {
    if (audioPlayerRef.current) {
      setIsPlaying(false);
      audioPlayerRef.current.pause();
      audioPlayerRef.current.currentTime = 0;
      setCurrentTime('00:00');
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };

  const handleAudioEnded = () => {
    if (audioPlayerRef.current) {
      setCurrentTime('00:00');
      audioPlayerRef.current.currentTime = 0;
    }
  };

  const updateTime = () => {
    if (audioPlayerRef.current) {
      const seconds = Math.floor(audioPlayerRef.current.currentTime);
      setCurrentTime(secondsToMMSS(seconds));
    }
  };

  const secondsToMMSS = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${padTo2Digits(minutes)}:${padTo2Digits(remainingSeconds)}`;
  };

  const padTo2Digits = (num) => {
    return num.toString().padStart(2, '0');
  };

  const handleStartTraining = () => {
    if (isPremium) {
      setCurrentTrainingPlan(selectedTrainingPlan);
      onPlanStarted();
    } else {
      setShowLandingPage(true);
    }
  };

  return (
    <StyledTrainingPlanDetail>
      <div className="image-container animated fadeIn">
        <img
          src={`${API_URL}/api/images/watercolor/${encodeURIComponent(
            selectedTrainingPlan.title.toLowerCase().replace(/ /g, '_'),
          )}.jpg`}
          alt=""
        />
      </div>

      <div className="info-container animated fadeIn">
        <PageTitle
          title={selectedTrainingPlan.title}
          text={selectedTrainingPlan.description}
        ></PageTitle>

        {isPlaying ? (
          <BlockButton
            isOutlined
            noBorder
            noMargin
            text={currentTime}
            onClick={pausePlayFile}
            icon={<StopIcon />}
          ></BlockButton>
        ) : (
          <BlockButton
            isOutlined
            noBorder
            noMargin
            text={t('play-intro')}
            onClick={playFile}
            icon={<PlayIcon />}
          ></BlockButton>
        )}

        {selectedTrainingPlan.results_and_benefits && (
          <>
            <SectionHeader title={t('coaching-page.benefits')}></SectionHeader>

            <ul>
              {selectedTrainingPlan.results_and_benefits.map((benefit, index) => (
                <li key={index}>
                  👍&nbsp;
                  {benefit}
                </li>
              ))}
            </ul>
          </>
        )}

        <SectionHeader title={t('coaching-page.whats-needed')}></SectionHeader>

        <ul>
          <li>
            ⏱️&nbsp;
            {t('coaching-page.whats-needed-time')}
          </li>
          <li>
            🎧&nbsp;
            {t('coaching-page.whats-needed-headphones')}
          </li>
          <li>
            🪷&nbsp;
            {t('coaching-page.whats-needed-calm')}
          </li>
        </ul>

        {/*<SectionHeader title={t('coaching-page.how-it-works')}></SectionHeader>*/}

        {/*trainingModules.map((item, index) => (
          <ContentCard
            style={{ textAlign: 'left' }}
            key={'listitem' + index}
            title={item.session_details.title}
            text={`${item.session_details.duration} - ${item.session_details.fullTags[0]}`}
            isLocked={!isPremium}
            onClick={() => {
              if (isPremium) {
                setSelectedSession(item.session_details);
              } else {
                setShowLandingPage(true);
              }
            }}
            image={`${API_URL}/api/images/watercolor/${encodeURIComponent(
              item.session_details.title.toLowerCase().replace(/ /g, '_'),
            )}.jpg`}
          ></ContentCard>
            ))*/}
      </div>

      {selectedSession && (
        <Modal
          onClose={() => {
            setSelectedSession(null);
          }}
        >
          <CoachingDetail
            selectedSession={selectedSession}
            handleClose={() => {
              setSelectedSession(null);
            }}
          ></CoachingDetail>
        </Modal>
      )}

      {showLandingPage && (
        <Modal
          onClose={() => {
            setShowLandingPage(false);
          }}
        >
          <OnboardingPage onClose={() => setShowLandingPage(false)}></OnboardingPage>
        </Modal>
      )}

      <div className="footer-button-area">
        <div className="footer-button-area-inner">
          {isPlaying ? (
            <BlockButton
              isOutlined
              text={currentTime}
              onClick={pausePlayFile}
              icon={<StopIcon />}
            ></BlockButton>
          ) : (
            <BlockButton
              isOutlined
              text={t('play-intro')}
              onClick={playFile}
              icon={<PlayIcon />}
            ></BlockButton>
          )}

          <BlockButton
            text={t('start-coaching-plan')}
            onClick={() => {
              handleStartTraining();
            }}
          ></BlockButton>
        </div>
      </div>
    </StyledTrainingPlanDetail>
  );
}
