import React, { useContext, useState, useEffect, createContext, useRef } from 'react'
import { BASE_URL, MOON_EXPLANATIONS_API, PLAN_API } from 'constants/URLS'
import dayjs from 'dayjs'
import { generateTaskList } from 'helper/taskList'
import { spreads } from 'constants/SPREADS'
import ChallengeDonePage from 'pages/ChallengeDonePage/ChallengeDonePage'
import Modal from 'components/Modal'
import { getLanguage } from 'helper/getLanguage'
import { shuffleArray } from 'helper/shuffleArray'
import LoadingState from 'components/LoadingState'

const APIContext = createContext()

export function APIContextProvider({ children, userID, userDisplayName, t }) {
  const firstRenderRef = useRef(true)
  const [userDataLoaded, setUserDataLoaded] = useState(false)
  const [fullyInitialized, setFullyInitialized] = useState(false)
  const [userData, setUserData] = useState()
  const [coachingSessions, setCoachingSessions] = useState()
  const [isPremium, setIsPremium] = useState(false)
  const [showChallengeDoneModal, setShowChallengeDoneModal] = useState(false)
  const [showLoadingModal, setShowLoadingModal] = useState(false)
  const [dataValidUntil, setDataValidUntil] = useState(dayjs().startOf('day'))
  const [updateCounter, setUpdateCounter] = useState(0)
  const [userLoggedIn, setUserLoggedIn] = useState(false)

  console.log('userData', userID, userDisplayName)

  useEffect(() => {
    const fetchData = async () => {
      const coachingResponse = await fetch(`${BASE_URL}/api/coaching/${getLanguage()}.json`)
      const coachingDataRaw = await coachingResponse.json()
      const coachingData = {
        ...coachingDataRaw,
        sessions_v3: coachingDataRaw.sessions_v3.filter((session) => session.hidden !== true),
      }
      setCoachingSessions(coachingData)

      // User data fetching logic
      if (userID && userDisplayName) {
        const userDataResponse = await fetch(
          `${BASE_URL}/api-user/?user_id=${userID}&display_name=${userDisplayName}`,
        )
        const userData = await userDataResponse.json()
        updateUserData(userData)
        handleCheckSubscription()
        setUserLoggedIn(true)
      } else {
        let userData = JSON.parse(localStorage.getItem('userData'))
        if (userData) {
          updateUserData(userData)
        } else {
          updateUserData({ userProfile: {} })
        }
      }

      setFullyInitialized(true)
    }

    fetchData()
  }, [])

  const updateUserData = (userData) => {
    if (!fullyInitialized) {
      // Update meal plan
      let newUserData = { ...userData }
      logThisOnLocal(newUserData)
      setUserData(newUserData)
      setUserDataLoaded(true)
    }
  }

  const logThisOnLocal = (data) => {
    if (window.location.href.includes('localhost')) {
      console.log(data)
    }
  }

  const handleCheckSubscription = async () => {
    try {
      const response = await fetch(`${PLAN_API}?user_id=${userID}&display_name=${userDisplayName}`)
      if (!response.ok) {
        setIsPremium(false)
        return
      }
      const data = await response.json()
      if (data.result !== '' && data.result !== 'free') {
        setIsPremium(true)
      } else {
        if (window.location.href.includes('localhost')) {
          setIsPremium(true)
        } else {
          setIsPremium(true)
        }
      }
    } catch (error) {
      setIsPremium(false)
    }
  }

  const saveUserData = (userData) => {
    if (userID && userDisplayName) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData),
      }

      fetch(
        `${BASE_URL}/api-user/?user_id=${userID}&display_name=${userDisplayName}`,
        requestOptions,
      )
        .then((response) => response.json())
        .then((data) => {
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            console.log('data saved: ', data)
          }
        })
    } else {
      localStorage.setItem('userData', JSON.stringify(userData))
    }
  }

  const updateUserProfile = (user) => {
    let newUserData = { ...userData }
    newUserData.userProfile = user
    setUserData(newUserData)
    saveUserData(newUserData)
  }

  const getTodaysTasks = async (moonData, t) => {
    let newUserData = { ...userData }
    let tasksOfTheDay = newUserData.tasksOfTheDay
    let tasksOfTheDayValidUntil = newUserData.tasksOfTheDayValidUntil

    if (tasksOfTheDay && dayjs(tasksOfTheDayValidUntil).isSame(dayjs(), 'day')) {
      return tasksOfTheDay
    } else {
      tasksOfTheDay = await generateTaskList(moonData, t)

      newUserData.tasksOfTheDay = tasksOfTheDay
      newUserData.tasksOfTheDayValidUntil = dayjs().startOf('day')

      setUserData(newUserData)
      saveUserData(newUserData)

      return tasksOfTheDay
    }
  }

  const getTodaysCoachings = async () => {
    let newUserData = { ...userData }
    let coachingsOfTheDay = newUserData.coachingsOfTheDay
    let coachingDataValidUntil = newUserData.tasksOfTheDayValidUntil

    if (coachingsOfTheDay && dayjs(coachingDataValidUntil).isSame(dayjs(), 'day')) {
      return coachingsOfTheDay
    } else {
      const { sessions_v3, training_plans } = coachingSessions

      const coachingsOfTheDay = {
        sessions: shuffleArray(sessions_v3).slice(0, 3),
        trainingPlans: shuffleArray(training_plans).slice(0, 3),
      }

      newUserData.coachingsOfTheDay = coachingsOfTheDay
      newUserData.coachingDataValidUntil = dayjs().startOf('day')

      setUserData(newUserData)
      saveUserData(newUserData)

      return coachingsOfTheDay
    }
  }

  const markTaskAsDone = (taskIndex) => {
    let newUserData = { ...userData }
    newUserData.tasksOfTheDay[taskIndex].isDone = true
    setUserData(newUserData)
    saveUserData(newUserData)
  }

  const setCurrentTrainingPlan = (trainingPlan) => {
    let newUserData = { ...userData }
    newUserData.currentTrainingPlan = trainingPlan
    setUserData(newUserData)
    saveUserData(newUserData)
  }

  const getFriends = () => {
    let newUserData = { ...userData }
    if (newUserData.userProfile === undefined) {
      newUserData.userProfile = {}
    }
    newUserData.userProfile.isMe = true
    newUserData.userProfile.name = t('friends.me')

    let friends = [newUserData.userProfile]
    let friendsFromStorage = newUserData.friends || []
    friendsFromStorage.forEach((friend) => {
      friend.isMe = false
      friends.push(friend)
    })

    return friends
  }

  const addFriend = (friend) => {
    let newUserData = { ...userData }
    if (!newUserData.friends) {
      newUserData.friends = []
    }
    newUserData.friends.push(friend)
    setUserData(newUserData)
    saveUserData(newUserData)
  }

  const saveAffirmation = (affirmation) => {
    let newUserData = { ...userData }
    newUserData.affirmation = affirmation
    setUserData(newUserData)
    saveUserData(newUserData)
  }

  const savePersonalizedAffirmationCategories = (personalizedAffirmationCategorys) => {
    let newUserData = { ...userData }
    newUserData.personalizedAffirmationCategorys = personalizedAffirmationCategorys
    setUserData(newUserData)
    saveUserData(newUserData)
  }

  const getPersonalizedAffirmationCategories = async () => {
    let newUserData = { ...userData }
    let personalizedAffirmationCategorys = newUserData.personalizedAffirmationCategorys

    if (personalizedAffirmationCategorys) {
      return personalizedAffirmationCategorys
    } else {
      personalizedAffirmationCategorys = [
        { category: 'abundance_of_life', isSelected: true },
        { category: 'activity', isSelected: true },
        { category: 'body_positivity', isSelected: true },
        { category: 'contact_with_yourself', isSelected: true },
        { category: 'gratitude', isSelected: true },
        { category: 'happiness_life', isSelected: true },
        { category: 'hard_times', isSelected: true },
        { category: 'love_relationships', isSelected: true },
        { category: 'personal_growth', isSelected: true },
        { category: 'physical_and_mental_health', isSelected: true },
        { category: 'positive_thinking', isSelected: true },
        { category: 'self_love', isSelected: true },
        { category: 'self_realization', isSelected: true },
        { category: 'stress_anxiety', isSelected: true },
      ]

      newUserData.personalizedAffirmationCategorys = personalizedAffirmationCategorys
      setUserData(newUserData)
      saveUserData(newUserData)

      return personalizedAffirmationCategorys
    }
  }

  const getTarotSpreads = async () => {
    let newUserData = { ...userData }
    let tarotSpreads = newUserData.tarotSpreads
    let tarotSpreadsValidUntil = newUserData.tarotSpreadsValidUntil

    if (tarotSpreads && dayjs(tarotSpreadsValidUntil).isSame(dayjs(), 'day')) {
      return tarotSpreads
    } else {
      tarotSpreads = spreads
      newUserData.tarotSpreads = tarotSpreads
      newUserData.tarotSpreadsValidUntil = dayjs().startOf('day')

      setUserData(newUserData)
      saveUserData(newUserData)

      return tarotSpreads
    }
  }

  const updateTarotSpread = (tarotSpread) => {
    let newUserData = { ...userData }
    const currentSpreadIndex = newUserData.tarotSpreads.findIndex(
      (spread) => spread.id === tarotSpread.id,
    )
    newUserData.tarotSpreads[currentSpreadIndex] = tarotSpread

    const revealedCards = tarotSpread.cards.filter((card) => card.isRevealed)
    if (revealedCards.length === tarotSpread.cards.length) {
      // Initialize tarotReadingHistory if it doesn't exist
      if (!newUserData.tarotReadingHistory) {
        newUserData.tarotReadingHistory = []
      }

      // Set date and formattedTitle for the tarotSpread
      const currentDate = dayjs().format('YYYY-MM-DD')
      const formattedTitle = `${t(
        'spreads.spreads.' + tarotSpread.id + '.title',
      )} - ${dayjs().format('DD.MM.YYYY')}`

      tarotSpread.date = currentDate
      tarotSpread.formattedTitle = formattedTitle

      // Find if a reading with the same date and title already exists
      const existingIndex = newUserData.tarotReadingHistory.findIndex(
        (reading) => reading.date === currentDate && reading.formattedTitle === formattedTitle,
      )

      if (existingIndex !== -1) {
        // If found, override the existing reading
        newUserData.tarotReadingHistory[existingIndex] = tarotSpread
      } else {
        // If not found, prepend the new tarotSpread
        newUserData.tarotReadingHistory.unshift(tarotSpread)
        newUserData.tarotReadingHistory.slice(0, 50)
      }
    }

    setUserData(newUserData)
    saveUserData(newUserData)
  }

  const removeFriend = (friend) => {
    let newUserData = { ...userData }
    newUserData.friends = newUserData.friends.filter((f) => f.name !== friend.name)
    setUserData(newUserData)
    saveUserData(newUserData)
  }

  const deleteProfile = () => {
    setUserData('')
    saveUserData('')
  }

  const deleteTasks = () => {
    let newUserData = { ...userData }
    newUserData.tasksOfTheDay = []
    newUserData.tasksOfTheDayValidUntil = dayjs().subtract(1, 'day')
    setUserData(newUserData)
    saveUserData(newUserData)
  }

  const triggerUpdate = () => {
    setUpdateCounter((prev) => prev + 1)
  }

  const getAstroEventExplaination = async (title) => {
    setShowLoadingModal(true)
    const response = await fetch(
      `${BASE_URL}/api/explanations/getAstroEventExplanation.php?titles[]=${title}&language=${getLanguage()}`,
    )
    setShowLoadingModal(false)
    return await response.json()
  }

  async function getMoonExplanation(moonDay, moonZodiac, zodiacSign, moonPhase) {
    //setShowLoadingModal(true);
    const moonExplanationsUrl = `${MOON_EXPLANATIONS_API}?moonDay=${encodeURIComponent(
      moonDay,
    )}&moonZodiac=${encodeURIComponent(
      moonZodiac,
    )}&userZodiac=${zodiacSign}&moonPhase=${moonPhase.replace('_', ' ')}&language=${getLanguage()}`

    try {
      const response = await fetch(moonExplanationsUrl)
      const data = await response.json()

      return data
    } catch (error) {
    } finally {
      //setShowLoadingModal(false);
    }
  }

  return (
    <APIContext.Provider
      value={{
        userDataLoaded,
        userData,
        coachingSessions,
        isPremium,
        getTodaysTasks,
        updateUserData,
        updateUserProfile,
        setUserData,
        saveUserData,
        deleteProfile,
        getFriends,
        markTaskAsDone,
        addFriend,
        removeFriend,
        saveAffirmation,
        savePersonalizedAffirmationCategories,
        getPersonalizedAffirmationCategories,
        getTarotSpreads,
        updateTarotSpread,
        setShowChallengeDoneModal,
        setCurrentTrainingPlan,
        getTodaysCoachings,
        triggerUpdate,
        getAstroEventExplaination,
        getMoonExplanation,
        deleteTasks,
        updateCounter,
        userLoggedIn,
      }}
    >
      {children}
      {showChallengeDoneModal && (
        <Modal
          onClose={() => {
            setShowChallengeDoneModal(false)
          }}
        >
          <ChallengeDonePage />
        </Modal>
      )}
      {showLoadingModal && (
        <Modal
          onClose={() => {
            setShowChallengeDoneModal(false)
          }}
        >
          <LoadingState
            title={t('article-page.transit-loading-headline')}
            text={t('article-page.transit-loading-text')}
          />
        </Modal>
      )}
    </APIContext.Provider>
  )
}

export function useAPI() {
  const context = useContext(APIContext)
  if (context === undefined) {
    throw new Error('Context must be used within a Provider')
  }
  return context
}
