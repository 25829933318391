import React, { useState, useEffect, useCallback } from 'react';
import { useAPI } from 'context/APIcontext';
import { API_URL, LEARN_TAROT_API } from 'constants/URLS';
import { useTranslation } from 'react-i18next';
import SectionHeader from 'components/SectionHeader';
import { getLanguage } from 'helper/getLanguage';
import ContentCard from 'components/ContentCard';
import StoryPage from 'pages/StoryPage/StoryPage';
import Modal from 'components/Modal';
import OnboardingPage from 'pages/OnboardingPage/OnboardingPage';
import TarotDetailPage from './TarotDetailPage';
import StyledSlider from 'components/StyledSlider';
import ContentCardBig from 'components/ContentCardBig';

export default function LearnTarotPage({ tarotCards }) {
  const { t } = useTranslation('common');
  const { isPremium } = useAPI();

  const [selectedArticle, setSelectedArticle] = useState(null);
  const [showLandingPage, setShowLandingPage] = useState(false);

  const [view, setView] = useState('lesson');
  const [allArticles, setAllArticles] = useState([]);
  const [beginnerArticles, setBeginnerArticles] = useState([]);
  const [readingTechniques, setReadingTechniques] = useState([]);
  const [tarotSpreads, setTarotSpreads] = useState([]);
  const [ethicsPractical, setEthicsPractical] = useState([]);
  const [personalGrowth, setPersonalGrowth] = useState([]);
  const [advancedArticles, setAdvancedArticles] = useState([]);

  const [selectedTarotCard, setSelectedTarotCard] = useState(null);

  useEffect(() => {
    getArticles();
  }, []);

  const getArticles = async () => {
    const articlesURL = `${LEARN_TAROT_API}?language=${getLanguage()}`;
    try {
      const response = await fetch(articlesURL);
      const data = await response.json();
      const articles = data;

      setAllArticles(articles.sort((a, b) => a.id - b.id));

      setBeginnerArticles(
        articles.filter((article) => article.category === 'basics').sort((a, b) => a.id - b.id),
      );
      setReadingTechniques(
        articles
          .filter((article) => article.category === 'reading_techniques')
          .sort((a, b) => a.id - b.id),
      );
      setTarotSpreads(
        articles
          .filter((article) => article.category === 'tarot_spreads')
          .sort((a, b) => a.id - b.id),
      );
      setEthicsPractical(
        articles
          .filter((article) => article.category === 'ethics_practical')
          .sort((a, b) => a.id - b.id),
      );
      setPersonalGrowth(
        articles
          .filter((article) => article.category === 'personal_growth')
          .sort((a, b) => a.id - b.id),
      );
      setAdvancedArticles(
        articles.filter((article) => article.category === 'advanced').sort((a, b) => a.id - b.id),
      );
    } catch (error) {
      return [];
    }
  };

  const handleCardClick = (article) => {
    if (!isPremium) {
      setShowLandingPage(true);
    } else {
      setSelectedArticle(article);
    }
  };

  const renderArticles = (articleList) =>
    articleList.map((article, index) => (
      <ContentCard
        key={article.id}
        title={article.title}
        text={article.shorttext}
        isLocked={!isPremium}
        isRoundedImage={true}
        onClick={() => handleCardClick(article)}
        circleText={`${index + 1}.`}
      />
    ));

  var sliderSettings = {
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.3,
        },
      },
    ],
  };

  return (
    <>
      <div className="tag-cloud hide-to-share">
        <div
          className={`tag ${view === 'lesson' ? 'is-active' : ''}`}
          onClick={() => setView('lesson')}
        >
          {t('learn-tarot.lessons')}
        </div>
        <div
          className={`tag ${view === 'cards' ? 'is-active' : ''}`}
          onClick={() => setView('cards')}
        >
          {t('learn-tarot.headline-cards')}
        </div>
        <div
          className={`tag ${view === 'cards-reversed' ? 'is-active' : ''}`}
          onClick={() => setView('cards-reversed')}
        >
          {t('learn-tarot.headline-cards-reversed')}
        </div>
      </div>

      <SectionHeader isLeft={true} title={t(`recommended`)}></SectionHeader>

      <div className="animated fadeIn">
        {allArticles && (
          <StyledSlider {...sliderSettings} hasMaxWidth={true}>
            {allArticles.map((sound, soundIndex) => (
              <ContentCardBig
                key={'sound' + soundIndex}
                title={sound.title}
                text={sound.shorttext}
                isLocked={!isPremium}
                onClick={() => {
                  if (!isPremium) {
                    setShowLandingPage(true);
                  } else {
                    setSelectedSound(sound);
                  }
                }}
              ></ContentCardBig>
            ))}
          </StyledSlider>
        )}
      </div>

      {view === 'lesson' && (
        <>
          {/*<SectionHeader title={t('basics')} isLeft />*/}
          <div className="animated fadeIn">{renderArticles(allArticles)}</div>
        </>
      )}

      {view === 'cards' && (
        <>
          <div className="animation animated fadeIn">
            {tarotCards.map((card, index) => (
              <ContentCard
                key={'card' + index}
                title={card.title}
                text={card.keywords}
                isLocked={!isPremium}
                onClick={() => {
                  if (!isPremium) {
                    setShowLandingPage(true);
                  } else {
                    setSelectedTarotCard(card);
                  }
                }}
                image={`${API_URL}/api/images/tarotcards/${card.image}.png`}
                isImageReversed={false}
              ></ContentCard>
            ))}
          </div>
        </>
      )}

      {view === 'cards-reversed' && (
        <>
          <div className="animation animated fadeIn">
            {tarotCards.map((card, index) => (
              <ContentCard
                key={'card' + index}
                title={card.titleReversed}
                text={card.keywordReversed}
                isLocked={!isPremium}
                onClick={() => {
                  if (!isPremium) {
                    setShowLandingPage(true);
                  } else {
                    setSelectedTarotCard(card);
                  }
                }}
                image={`${API_URL}/api/images/tarotcards/${card.image}.png`}
                isImageReversed={true}
              ></ContentCard>
            ))}
          </div>
        </>
      )}

      {selectedArticle && (
        <Modal onClose={() => setSelectedArticle(null)}>
          <StoryPage
            title={selectedArticle.title}
            text={selectedArticle.content}
            handleClose={() => setSelectedArticle(null)}
          />
        </Modal>
      )}

      {showLandingPage && (
        <Modal onClose={() => setShowLandingPage(false)}>
          <OnboardingPage onClose={() => setShowLandingPage(false)} />
        </Modal>
      )}

      {selectedTarotCard && (
        <Modal
          onClose={() => {
            setSelectedTarotCard(null);
          }}
        >
          <TarotDetailPage
            selectedCard={selectedTarotCard}
            reversed={view === 'cards-reversed' ? true : false}
            handleClose={() => {
              setSelectedTarotCard(null);
            }}
          ></TarotDetailPage>
        </Modal>
      )}
    </>
  );
}
