// eslint-disable-file missing semicolon
import { APIContextProvider } from './context/APIcontext'
import { AlertProvider } from './context/AlertContext' // Import useAlert hook
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import OverviewPage from './pages/OverviewPage/OverviewPage'
import { StyledApp } from './StyledApp'
import { StyledElements } from 'StyledElements'
import { ShareImageContextProvider } from 'context/ShareContext'
import { AuthContext, AuthProvider } from 'context/AuthContext'

function App({ data }) {
  const { t } = useTranslation('common')

  // Get the context value from AuthContext
  const authContext = useContext(AuthContext)

  // Make sure to check if the context exists and if `user` exists
  const userID = authContext?.user?.userID
  const userDisplayName = authContext?.user?.userDisplayName

  return (
    <StyledApp>
      <AlertProvider>
        <StyledElements>
          <AuthProvider>
            {data.module === 'landing-page' && (
              <APIContextProvider>
                <></>
              </APIContextProvider>
            )}
            {data.module === 'checkout-page' && (
              <APIContextProvider>
                <></>
              </APIContextProvider>
            )}
            {data.module === 'onboarding' && <OnboardingPage />}
            {data.module === 'app-widget' && (
              <>
                <APIContextProvider userID={userID} userDisplayName={userDisplayName} t={t}>
                  <ShareImageContextProvider>
                    <OverviewPage startView={data.startView}></OverviewPage>
                  </ShareImageContextProvider>
                </APIContextProvider>
              </>
            )}
          </AuthProvider>
        </StyledElements>
      </AlertProvider>
    </StyledApp>
  )
}

export default App
